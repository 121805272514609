import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { useDispatch } from 'react-redux';

// COMPONENTS & UTILS
import theme from 'styles/mui/theme';
import { useGetUserDetailQuery } from 'services/private/user';
import { getUserDetail, onLoggedOut } from 'store/slices/authSlice';
import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import AppRoutes from './routes';

function App() {
  const dispatch = useDispatch();
  const { isSuccess, data: response, isError, isLoading } = useGetUserDetailQuery();

  useEffect(() => {
    if (response && isSuccess) {
      dispatch(getUserDetail(response?.data));
    } else if (isError) {
      dispatch(onLoggedOut());
    }
  }, [response, isSuccess, isError]);

  if (isLoading) {
    return <GlobalLoader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider preventDuplicate anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <AppRoutes />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
