import React, { useCallback, useState } from 'react';
import { Avatar, Box, Divider, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Link } from 'react-router-dom';

// ASSETS & STYLES
import logoImg from 'assets/logo-icon.png';
import styles from 'styles/common/layout.module.scss';
import { onLoggedOut } from 'store/slices/authSlice';
import useGetUserData from 'customHooks/useGetUserData';

function Navbar() {
  const dispatch = useDispatch();
  const [profileMenu, setProfileMenu] = useState(false);
  const { firstName, userId, avatar } = useGetUserData();

  const handleOpenMenu = useCallback(e => {
    setProfileMenu(e.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setProfileMenu(null);
  }, []);

  const handleLogout = useCallback(() => {
    setProfileMenu(null);
    dispatch(onLoggedOut());
  }, []);

  return (
    <Box p={2} className={styles.navbarContainer}>
      <img className={styles.styles} src={logoImg} alt="Logo" width={40} />

      <Stack className="pointer" direction="row" alignItems="center" spacing={2} onClick={handleOpenMenu}>
        <Avatar src={avatar || '/'} alt={firstName} />

        <Typography variant="body1">{firstName}</Typography>

        <KeyboardArrowDown />
      </Stack>

      <Menu
        key={profileMenu}
        open={!!profileMenu}
        anchorEl={profileMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ '& .MuiPaper-root': { minWidth: '120px', marginTop: '10px' } }}
      >
        <MenuItem
          className="justify-content-center"
          component={Link}
          to={`/profile/${userId}`}
          onClick={handleCloseMenu}
        >
          Profile
        </MenuItem>

        <Divider className="my-1" />

        <MenuItem className="justify-content-center" onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default Navbar;
