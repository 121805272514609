import { privateAPI } from '.';

export const userAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getUserDetail: build.query({
      query: () => '/client-portal/get-user-detail',
      providesTags: ['GetUserDetails'],
    }),
  }),
});

export const { useGetUserDetailQuery } = userAPI;
