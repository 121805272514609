import React from 'react';
import { Box, List } from '@mui/material';
import { Dashboard, Folder, Settings, Event, Chat } from '@mui/icons-material';

// COMPONENTS & STYLES
import styles from 'styles/common/layout.module.scss';
import SidebarItem from './SidebarItem';

function Sidebar() {
  return (
    <Box className={styles.sidebarContainer}>
      <List>
        <SidebarItem icon={<Dashboard />} text="Requests" path="/" />
        <SidebarItem icon={<Folder />} text="Documents" path="/documents" />
        <SidebarItem icon={<Event />} text="Calendar" path="/calendar" />
        <SidebarItem icon={<Chat />} text="Messenger" path="/messenger" />
        <SidebarItem icon={<Settings />} text="Settings" path="/settings" />
      </List>
    </Box>
  );
}

export default Sidebar;
