import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  user: {
    id: 1,
    first_name: 'John',
    last_name: 'Doe',
    is_superuser: true,
    name: 'John Doe',
    email: 'johndoe@test.com',
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onLoggedIn: (state, action) => {
      const { user, access_token: token } = action.payload;

      localStorage.setItem('token', token);

      return { ...state, isAuthenticated: true, user };
    },
    getUserDetail: (state, action) => ({
      ...state,
      isAuthenticated: true,
      user: action.payload,
    }),
    onLoggedOut: () => {
      localStorage.removeItem('token');

      return { ...initialState };
    },
  },
});

export const { onLoggedIn, getUserDetail, onLoggedOut } = authSlice.actions;
export default authSlice.reducer;
