import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';

// AUTH PAGES
const LoginPage = lazy(() => import('containers/pages/login'));
const ForgotPassword = lazy(() => import('containers/pages/forgotPassword'));
const ResetPassword = lazy(() => import('containers/pages/resetPassword'));

// PRIVATE PAGES
const Requests = lazy(() => import('containers/pages/requests'));
const Documents = lazy(() => import('containers/pages/documents'));
const ProjectDetails = lazy(() => import('containers/pages/projectDetails'));
const SettingsPage = lazy(() => import('containers/pages/settings'));
const Messenger = lazy(() => import('containers/pages/messenger'));
const Calendar = lazy(() => import('containers/pages/calendar'));

function AppRoutes() {
  return (
    <BrowserRouter>
      <Suspense fallback={<GlobalLoader />}>
        <Routes>
          <Route path="/">
            <Route path="auth" element={<PublicRoutes />}>
              <Route path="login" element={<LoginPage />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>

            <Route path="/" element={<PrivateRoutes />}>
              <Route index element={<Requests />} />

              <Route path="requests" element={<Outlet />}>
                <Route index element={<Requests />} />
                <Route path=":id" element={<ProjectDetails />} />
              </Route>

              <Route path="settings" element={<SettingsPage />} />
              <Route path="messenger" element={<Messenger />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="documents" element={<Documents />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppRoutes;
