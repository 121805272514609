import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useGetUserData = () => {
  const user = useSelector(state => state.auth.user);

  const userId = useMemo(() => user.id, [user]);
  const username = useMemo(() => user.name, [user]);
  const avatar = useMemo(() => user.avatar, [user]);
  const email = useMemo(() => user.email, [user]);
  const firstName = useMemo(() => user.first_name, [user]);
  const lastName = useMemo(() => user.last_name, [user]);
  const phoneNumber = useMemo(() => user.phone_number, [user]);

  return { userId, username, email, firstName, lastName, avatar, phoneNumber };
};

export default useGetUserData;
